@import url('https://fonts.googleapis.com/css2?family=Biryani:wght@200;400;600;700&display=swap');
@font-face {
  font-family: 'Times';
  src: url(./styles/fonts/times.ttf) format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Times';
  src: url(./styles/fonts/timesBold.ttf) format('truetype');
  font-weight: 700;
}

body {
  margin: 0;
  font-family: 'Biryani', 'Times', sans-serif;
  letter-spacing: 2px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.times {
  font-family: 'Times', sans-serif;
  letter-spacing: 0px;
}

:focus {
  outline: none;
}

.select {
  text-decoration: none;
  color: #f8f8f8;
  border: 3px solid #f8f8f8;
}

.select:hover {
  color: #161616;
  background-color: #f8f8f8;
}

.selectDark {
  text-decoration: none;
  color: #161616;
  background-color: #ffffff;
  border: 3px solid #161616;
}

.selectDark:hover {
  color: #f8f8f8;
  background-color: #161616;
}

.noDecor {
  text-decoration: none;
}

.hoverLink {
  color: #161616;
  text-decoration: none;
}

.hoverLink:hover {
  color: #161616;
  text-decoration: underline;
}
